import React, {useState} from "react";
import FoodType from "../../fridge/components/FoodType";
import Modal from "../../../components/Modal";

function AddListItemModal({button, title, body, SaveHandler}) {
    const [name, setName] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [measurement, setMeasurement] = useState(null);

    var modalBody =  <form>
                        <div className="row mb-3 col-12">
                            <label className="form-label">Name:</label>
                            <input type="text" value={name} />
                        </div>
                        <div className="row mb-3 col-12">
                            <label className="form-label">Quantity: </label>
                            <input type="text" value={quantity} />
                        </div>

                        <div className="row mb-3 col-12">
                            <label className="form-label">Measurement: </label>
                            <FoodType FoodSelectHandler={setMeasurement} />
                        </div>
                    </form>;

    const saveHandler = async () => {

    };

    return (
        <>
            {button}
            <Modal title="Add Item" body={modalBody} SaveHandler={saveHandler} />
        </>
    );
}

export default AddListItemModal;
