import React, { useState } from 'react';
import {PerformRequest, GET, POST} from '../utils/JsonFetcher';
import {SetToken, SetUser} from '../utils/TokenStorage';
import {Link, useNavigate, useSearchParams} from "react-router-dom";

function LoginPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error Logging In!");
    const [searchParams, setSearchParams] = useSearchParams();
    var userSuccessString = searchParams.get("user_success");
    var verifySuccessString = searchParams.get("verify");

    const handleSubmit = async (event) => {
        event.preventDefault();
        let result = await PerformRequest('login_check', POST, {username: username, password: password}, true);

        if (result && result.token) {
            setLoginError(false);
            SetToken(result.token);
            SetUser(result.user);

            navigate('/fridges');
            window.location.reload();
        } else {
            if(result["errorMessage"]) {
                setErrorMessage(result["errorMessage"]);
            }

            setLoginError(true);
        }
    };

    const PasswordSetRender =
        <div className="form-group">
            <input
                id="password-field"
                className="form-control"
                required=""
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <span toggle="#password-field" className="field-icon toggle-password"><i className="bi bi-eye-fill"
                                                                                     onClick={() => {
                                                                                         setShowPassword(true)
                                                                                     }}></i></span>
        </div>;

    const PasswordViewRender =
        <div className="form-group">
            <input
                id="password-field"
                className="form-control"
                required=""
                type="text"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <span toggle="#password-field" className="field-icon toggle-password"><i className="bi bi-eye-slash-fill"
                                                                                     onClick={() => {
                                                                                         setShowPassword(false)
                                                                                     }}></i></span>
        </div>

    const InputToRender = showPassword ? PasswordViewRender : PasswordSetRender;

    return (
        <div className="landing landing-full">
            <div className="navbar bg-dark">
                <div className="container homepage-container">
                    <div>
                        <h4 className="pt-2"><Link to="/">MoneyMan Tracker</Link></h4>
                    </div>
                    <div>
                        <Link to="/login">
                            Login
                        </Link>

                        <Link to="/premium/signup">
                            Register
                        </Link>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center login">
                    <div className="col-md-7 col-lg-5">
                        <div className="wrap">
                            <div className="img money"></div>
                            <div className="login-wrap p-4 p-md-5">
                                <div className="d-flex">
                                    <div className="w-100">
                                        <h3 className="mb-4">Sign In</h3>

                                        {loginError &&
                                         <h5 className="text-danger"><i class="bi bi-exclamation-triangle-fill"></i> {errorMessage} </h5>
                                        }

                                        {userSuccessString &&
                                            <h5 className="text-success"><i
                                                className="bi bi-exclamation-triangle-fill"></i> User Created! Please check your email for a verification link.</h5>
                                        }

                                        {verifySuccessString &&
                                            <h5 className="text-success"><i
                                                className="bi bi-exclamation-triangle-fill"></i> User Verified! Please Login now.</h5>
                                        }
                                    </div>
                                </div>
                                <form action="#" className="signin-form" onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            value={username}
                                            placeholder="Username"
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                    {InputToRender}
                                    <div className="form-group">
                                        <button type="submit"
                                                className="form-control btn btn-primary rounded submit px-3">Sign
                                            In
                                        </button>
                                    </div>
                                    <div className="form-group d-flex justify-content-between">
                                        <div>
                                            <Link to="/forgot">Forgot Password</Link>
                                        </div>

                                        <div>
                                            Not a member? <Link to="/premium/signup">Register</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LoginPage;