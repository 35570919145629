import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function ListItem({items}) {
    const [selected, setSelected] = useState([]);

    const ToggleSelect = (id) => {
        var selectedIndex = selected.indexOf(id);

        if(selectedIndex > -1) {
            var localSelected = selected.splice(selectedIndex, 1);
            setSelected(localSelected);
        } else {
            var localSelected = selected;
            localSelected.push(id);
            setSelected(localSelected);
        }
    }

    return items.map(({ id, name, quantity }) => (
        <li key={"list-item-" + id}>
            <div className="d-flex">
                <div className="input-group-text">
                    <input type="checkbox" onClick={(e) => {
                        e.stopPropagation();
                        ToggleSelect(id)
                    } } />
                </div>

                <img className="mx-2" src="https://fakeimg.pl/50/" />

                <div className="align-self-start">
                    <div className="fw-bold">{name}</div>
                    <span className="expiration">
                        <span className="smidge">Qty: {quantity}</span>
                    </span>
                </div>

                <div className="ms-auto my-auto">
                    <i className="bi bi-caret-right-fill"></i>
                </div>
            </div>
        </li>
    ));
}

export default ListItem;