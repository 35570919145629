import React, { useState } from 'react';
import {PerformRequest, POST} from '../utils/JsonFetcher';
import {Link, useNavigate} from "react-router-dom";

function ForgotPage() {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let result = await PerformRequest('users/reset-password', POST, {email: email}, true);

        if(result) {
            setMessageSent(true);
        }
    };

    return (
        <div className="landing landing-full">
            <div className="navbar bg-dark">
                <div className="container homepage-container">
                    <div>
                        <h4 className="pt-2"><Link to="/">MoneyMan Tracker</Link></h4>
                    </div>
                    <div>
                        <Link to="/login">
                            Login
                        </Link>

                        <Link to="/premium/signup">
                            Register
                        </Link>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center login">
                    <div className="col-md-7 col-lg-5">
                        <div className="wrap">
                            <div className="img forgot"></div>
                            <div className="login-wrap p-4 p-md-5">
                                <div className="d-flex">
                                    <div className="w-100">
                                        <h3 className="mb-4">Forgot Password</h3>

                                        { messageSent &&
                                            <h5 className="text-success"><i class="bi bi-hand-thumbs-up-fill"></i> Email
                                                Sent!</h5>
                                        }
                                    </div>
                                </div>
                                <form action="#" className="signin-form" onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            value={email}
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit"
                                                className="form-control btn btn-primary rounded submit px-3">Submit
                                        </button>
                                    </div>
                                    <div className="form-group d-md-flex justify-content-between">
                                        <div>
                                            <Link to="/login">Log In</Link>
                                        </div>

                                        <div>
                                            <Link to="/premium/signup">Register</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
            );
            }

            export default ForgotPage;