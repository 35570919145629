import React from "react";
import {Link} from "react-router-dom";

function Invitation({items}) {
    if(!items || items.length === 0) {
        return <></>;
    }

    return items.map(({ id, name }) => (
        <li key={"invitation-" + id} className="list-group-item">
            <Link to={"/invitations/"+id}>
                <img src="https://fakeimg.pl/50/" />

                <div className="fw-bold">{name}</div>
            </Link>
        </li>
    ));
}

export default Invitation;