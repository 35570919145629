import React from "react";
import CreatableSelect from 'react-select/creatable';

function FoodType(defaultSelectedValue, FoodSelectHandler) {
    const types = [
        {value: "Cans", label: "Cans"},
        {value: "Cups", label: "Cups"},
        {value: "Gallons", label: "Gallons"},
        {value: "Grams", label: "Grams"},
        {value: "Jars", label: "Jars"},
        {value: "Kilograms", label: "Kilograms"},
        {value: "Liters", label: "Liters"},
        {value: "Milliliters", label: "Milliliters"},
        {value: "Ounces", label: "Ounces"},
        {value: "Pints", label: "Pints"},
        {value: "Pounds", label: "Pounds"},
        {value: "Quarts", label: "Quarts"},
        {value: "Tablespoons", label: "Tablespoons"},
        {value: "Teaspoons", label: "Teaspoons"}
    ];

    return (
        <>
            <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={(defaultSelectedValue) ? defaultSelectedValue : types[0]}
                isClearable
                isSearchable
                name="color"
                options={types}
                onChange={FoodSelectHandler}
            />
        </>
    );
}

export default FoodType;