import React, {useState, useEffect, useRef} from "react";
import FridgeItem from "./FridgeItem";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {PerformRequest} from "../../utils/JsonFetcher";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import {redirect} from "react-router-dom";

import { Line } from "react-chartjs-2";
import { ChartData, ChartArea } from 'chart.js';
import ClipLoader from "react-spinners/ClipLoader";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CategoryHelper from "./CategoryHelper";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import Modal from "../../components/Modal";
import Import from "./Import";
import House from "./images/house.jpeg";
import Car from "./images/car.jpeg";
import Diploma from "./images/diploma.jpeg";
import Stock from "./images/stock.jpeg";
import Retirement from "./images/retirement.jpg";
import EditAccount from "./EditAccount";
import FridgeCreate from "./FridgeCreate";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

function Fridge() {
    let newDate = new Date();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let newDate2 = new Date();
    let currentMonth = newDate2.getMonth() + 1;
    let currentYear = newDate2.getFullYear();

    let { id } = useParams();

    const [fridge, setFridge] = useState("");
    const [totals, setTotals] = useState("");
    const [incomes, setIncomes] = useState("");
    const [categories, setCategories] = useState("");
    const [sort, setSort] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedYear, setSelectedYear] = useState(year);
    const [yearValues, setYearValues] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [balance, setBalance] = useState(0);
    const [endDate, setEndDate] = useState(new Date());
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndex2, setTabIndex2] = useState(0);
    const [uncategorizedOnly, setUncategorizedOnly] = useState(false);
    const [rules, setRules] = useState([]);
    const navigate = useNavigate();

    var ruleName = useRef(null);
    var term = useRef(null);

    function getPreviousMonthYear(date) {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - 1);

        const year = newDate.getFullYear();
        const month = newDate.getMonth() + 1; // JavaScript months are 0-indexed

        return { year, month };
    }

    function getNextMonthYear(date) {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);

        const year = newDate.getFullYear();
        const month = newDate.getMonth() + 1; // JavaScript months are 0-indexed

        return { year, month };
    }

// Example usage:
    const today = new Date();

    useEffect(() => {
        const fetchData = async () => {
            performSearch();
        }

        fetchData()
            .catch(console.error);
    }, []);

    const searchDates = async (month = null, year = null) => {
        performSearch(month, year);
    }

    const performSearch = async (monthOverride = null, yearOverride =null) => {
        setLoading(true);

        var monthToPick = (monthOverride) ? monthOverride : selectedMonth;
        var yearToPick = (yearOverride) ? yearOverride : selectedYear;
        let result = await PerformRequest('fridge/accounts/' + id + "/items", "POST", {month: monthToPick, year: yearToPick});
        let result2 = await PerformRequest('rules/rules');
        let result3 = await PerformRequest('category/categories');

        if(result && result2 && result3) {
            setFridge(result.data);
            setTotals(result.totals);
            setIncomes(result.incomes);
            setCategories(result.categories);
            setYearValues(result.availableYears);
            setRules(result2);
            setAllCategories(result3);
            setBalance(result.balance);
            setLoading(false);
        }
    }

    const deleteAccount = async () => {
        let result = await PerformRequest('fridge/accounts/' + id, "DELETE");
        return navigate('/fridges');
    }

    const viewMonth = async () => {
        setSelectedMonth(month)
        performSearch();
    }

    const viewFullYear = async () => {
        setSelectedMonth("any")
        performSearch("any");
    }

    const handleRuleSubmit = async () => {
        setLoading(true);
        let result = await PerformRequest('rules/rule', 'PUT', {name: ruleName.current.value, categoryName: selectedCategory, term: term.current.value});

        if(result) {
            setLoading(false);
            searchDates();
        }
    }

    const applyTerm = async (id) => {
        let result = await PerformRequest('rules/apply', 'POST', {id: id});
        searchDates();
    }

    const deleteRule = async (id) => {
        let result = await PerformRequest('rules/rules', 'DELETE', {id: id});
        let result2 = await PerformRequest('rules/rules');
        setRules(result2);
    }

    const getToday = async () => {
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var dateString = months[selectedMonth - 1] + " " + selectedYear;
        return new Date(dateString);
    }

    const changeMonthBackwards = async () => {
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var dateString = months[selectedMonth - 1] + " " + selectedYear;
        var today = new Date(dateString);

        var date = getPreviousMonthYear(today);
        setSelectedMonth(date.month);
        setSelectedYear(date.year);
        searchDates(date.month, date.year);
    }

    const changeMonthForwards = async () => {
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var dateString = months[selectedMonth - 1] + " " + selectedYear;
        var today = new Date(dateString);

        var date = getNextMonthYear(today);
        setSelectedMonth(date.month);
        setSelectedYear(date.year);
        searchDates(date.month, date.year);
    }

    if(loading) {
        return <div>
            <ClipLoader
                className="ml-5"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>;
    }

    if(sort) {
        if(fridge.items.length) {
         //   fridge.items = fridge.items.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }

        var sortRender = <i className="bi bi-sort-alpha-down"></i>;
    } else {
        if(fridge.items.length) {
           // fridge.items = fridge.items.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
        }

        var sortRender = <i className="bi bi-sort-alpha-down-alt"></i>;
    }

    const dataSpending = generateData(totals, selectedMonth, "Account Spending", "rgb(138,0,0)", "rgb(255,0,0)");
    const dataIncome = generateData(incomes, selectedMonth, "Account Income", "rgba(4,138,30,0.2)", "rgb(11,103,1)");

    var spendingTotal = getTotal(totals);
    var incomeTotal = getTotal(incomes);

    var mappedCategories = "";
    if(categories.length > 0) {
        mappedCategories = categories.map(({name, total}) => {
            var mappedTotals = getTotal(totals, false);
            var percentage = (parseInt(Math.abs(total)) / parseInt(mappedTotals)) * 100;

            return <tr>
                <td>
                    <div className="progress-label-container">
                        <div className="progress">
                            <div className="progress-bar progress-bar-fixed" role="progressbar" style={{"width": percentage + "%"}}
                                 aria-valuenow="25"
                                 aria-valuemin="0" aria-valuemax="100"> {name}
                            </div>
                        </div>
                    </div>
                </td>
                <td><CurrencyFormat value={Math.abs(total)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                </td>
            </tr>;
        });
    }

    var mappedRules = "";
    if(rules.length > 0) {
        mappedRules = rules.map(({id, name, term, categoryName}) => {
            return <tr>
                <td>{name}</td>
                <td>{term}</td>
                <td>{categoryName}</td>
                <td>
                    <button className="btn btn-primary" onClick={() => applyTerm(id)}>Apply Now</button>
                </td>
                <td>
                    <button onClick={(e) => deleteRule(id)} className="btn btn-danger">Delete</button>
                </td>
            </tr>;
        });
    }

    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var monthDisplay = months[selectedMonth - 1] + ' ' + selectedYear;

    var categoryOptions = allCategories.map(category => {
        return (
            {
                value: category.id,
                label: category.name
            }
        );
    });

    var isNotCurrentDate = currentMonth != selectedMonth || currentYear != selectedYear;

    var icon = "";
    switch (fridge.type) {
        case "Mortgage":
            icon = <i className="bi bi-house-door-fill"></i>;
            break;
        case "Car Loan":
        case "Auto Loan":
            icon = <i className="bi bi-car-front-fill"></i>;
            break;
        case "Savings":
        case "Debit":
            icon = <i className="bi bi-cash-coin"></i>;
            break;
        case "Student Loan":
            icon = <i className="bi bi-backpack-fill"></i>;
            break;
        case "Credit Card":
        case "Credit":
            icon = <i className="bi bi-credit-card"></i>;
            break;
        case "Stock":
        case "Stocks":
            icon = <i className="bi bi-graph-up-arrow"></i>;
            break;
        case "401K":
        case "401k":
        case "403b":
        case "403B":
            icon = <i className="bi bi-bar-chart"></i>;
            break;
    }

    return (
        <div key={fridge.id}>
            <div className="d-flex justify-content-between">
                <h3>{fridge.name} <br/> <span className="sub-text">{fridge.type} {icon}</span></h3>

                {false && fridge.items.length > 0 &&
                    <div>
                        <button onClick={() => viewFullYear()}>Year</button>
                        <button onClick={() => viewMonth()}>Month</button>

                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}/>

                        <button onClick={() => searchDates()}>Search</button>
                        <button onClick={() => setUncategorizedOnly(!uncategorizedOnly)}>Uncategorized Only</button>
                    </div>
                }
                <h5>
                    <CurrencyFormat value={balance} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                </h5>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <Import currentAccount={fridge.id}/>
                </div>
                <div className="col-md-3 col-sm-12">
                    <EditAccount currentAccount={fridge.id}/>
                </div>
            </div>

            <h3 className="date-header">
                <small>
                    <i className="bi bi-arrow-left" onClick={changeMonthBackwards}></i>
                </small>

                <span className="mx-2">{monthDisplay}</span>

                {isNotCurrentDate &&
                    <small>
                        <i className="bi bi-arrow-right" onClick={changeMonthForwards}></i>
                    </small>
                }
            </h3>

            <div className="card graph-card col-sm-12 col-md-8 mb-5 shadow p-3 rounded">
                <Tabs className="chart-tabs" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab><span className="tab-heading">Spending</span> <br/> <span
                            className="tab-total">{spendingTotal}</span></Tab>

                        {fridge.type != "Credit" &&
                            <Tab><span className="tab-heading">Income</span> <br/> <span
                                className="tab-total">{incomeTotal}</span></Tab>
                        }

                        <Tab><span className="tab-heading">Spending Categories</span> <br/> <span
                            className="tab-total">{categories.length} </span></Tab>
                    </TabList>
                    <TabPanel className="card-pane">
                        <div className="mb-5 ml-5 mr-5">
                            <Line data={dataSpending}/>
                        </div>
                    </TabPanel>
                    {fridge.type != "Credit" &&
                        <TabPanel className="card-pane">
                            {(fridge.items.length > 0 && fridge.type != "Credit") &&
                                <div className="mb-5 ml-5 mr-5">
                                    <Line data={dataIncome}/>
                                </div>
                            }
                        </TabPanel>
                    }
                    <TabPanel className="card-pane">
                        {categories.length > 0 &&
                            <div className="mb-5 ml-5 mr-5">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Total</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {mappedCategories}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </TabPanel>
                </Tabs>
            </div>

            {(fridge.items.length > 0 && !loading) &&
                <div className="card graph-card mb-5 shadow p-3 rounded">
                    <Tabs className="chart-tabs" selectedIndex={tabIndex2} onSelect={(index) => setTabIndex2(index)}>
                        <TabList>
                            <Tab><span className="tab-heading">Items</span> <br/> <span
                                className="tab-total"></span></Tab>
                            <Tab><span className="tab-heading">Rename Rules</span> <br/> <span
                                className="tab-total"></span></Tab>
                        </TabList>
                        <TabPanel className="card-pane">
                            <FridgeItem items={fridge.items} uncategorizedOnly={uncategorizedOnly}/>
                        </TabPanel>
                        <TabPanel className="card-pane">
                            <h4 className="pt-2">Create New Rule</h4>
                            <form onSubmit={handleRuleSubmit}>
                                <div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Name:</span>
                                        <input
                                            id="rule_name"
                                            ref={ruleName}
                                            className="form-control"
                                            type="text"
                                            placeholder="My Electric Bill Pattern"
                                        />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Search Term:</span>
                                        <input
                                            id="term"
                                            className="form-control"
                                            type="text"
                                            ref={term}
                                            placeholder="My Electric Bill"
                                        />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Category:</span>
                                        <CategoryHelper otherHandler={setSelectedCategory}
                                                        extraOptions={categoryOptions}/>
                                    </div>

                                    <div className="input-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>

                                    <hr/>

                                    <h5>Current Account Rules</h5>

                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Search Term</th>
                                            <th scope="col">Category</th>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {mappedRules}
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </div>
            }

            <div>
                <Modal title="Delete Account" buttonText="Delete Account" body={"Delete Account " + fridge.name + "?"}
                       SaveHandler={deleteAccount}/>
            </div>

            {fridge.items.length <= 0 &&
                <h5>No data exists for the Account. Please import data from the main Accounts page.</h5>
            }
        </div>
    );
}

function generateData(data, month = null, label, backgroundColor, borderColor) {
    var labels = [];
    var chartData = [];

    if (data.length > 0) {
        if (month != "any") {
            data.forEach((item) => {
                labels.push(item.d_month + "/" + item.d_day);
                chartData.push(Math.abs(item.total));
            })
        } else {
            data.forEach((item) => {
                labels.push(item.d_month + "/" + item.d_year);
                chartData.push(Math.abs(item.total));
            })
        }
    }

    const output = {
        labels: labels,
        datasets: [
            {
                label: label,
                data: chartData,
                fill: true,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
            }
        ]
    };

    return output;
}

function getTotal(data, returnFormatted = true) {
    var options = {style: 'currency', currency: 'USD'};
    var formatter = new Intl.NumberFormat('en-US', options);

    var spendingAmount = 0;
    data.forEach((item) => {
        spendingAmount += Math.abs(item.total);
    });

    if(returnFormatted) {
        var result = formatter.format(spendingAmount);
        return result;
    } else {
        return spendingAmount;
    }
}

export default Fridge;