import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter} from "react-router-dom";
import {RouterProvider} from "react-router";

import App from './App';
import Error from "./Error";
import {IsLoggedIn} from "./utils/TokenStorage";

import "bootstrap-icons/font/bootstrap-icons.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App page={IsLoggedIn() ? "fridges": "home"}/>,
        errorElement: <Error />,
    },
    {
        path: "login",
        element: <App page={"login"} />,
        errorElement: <Error />,
    },
    {
        path: "verify",
        element: <App page={"verify"} />,
        errorElement: <Error />,
    },
    {
        path: "forgot",
        element: <App page={"forgot"} />,
        errorElement: <Error />,
    },
    {
        path: "logout",
        element: <App page={"logout"} />,
        errorElement: <Error />,
    },
    {
        path: "register",
        element: <App page={"signUp"} />,
    },
    {
        path: "fridges",
        element: <App page={"fridges"} />,
    },
    {
        path: "fridge/:id",
        element: <App page={"fridge"} />,
    },
    {
        path: "fridge/:id/create",
        element: <App page={"fridgeItemAdd"} />,
    },
    {
        path: "fridge/:id/item/:itemId",
        element: <App page={"fridgeItemEdit"} />,
    },

    {
        path: "fridges/create",
        element: <App page={"fridgeCreate"} />,
    },

    {
        path: "fridge/:id/scan",
        element: <App page={"fridgeItemScan"} />,
    },

    {
        path: "recipes",
        element: <App page={"recipes"} />,
    },

    {
        path: "favorites",
        element: <App page={"favorites"} />,
    },

    {
        path: "recipe/:id",
        element: <App page={"recipeItem"} />,
    },

    {
        path: "list",
        element: <App page={"list"} />,
    },

    {
        path: "scan",
        element: <App page={"fridgeItemScan"} />,
    },

    {
        path: "households",
        element: <App page={"households"} />,
    },

    {
        path: "households/:id",
        element: <App page={"household"} />,
    },

    {
        path: "households/:id/invitations",
        element: <App page={"invitations"} />,
    },

    {
        path: "premium/signup",
        element: <App page={"premiumSignup"} />,
    },
]);

ReactDOM.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
