import React, {useEffect, useState} from 'react';
import {Logout} from '../utils/TokenStorage';
import { useNavigate } from "react-router-dom";

function LogoutPage() {
    const navigate = useNavigate();

    useEffect(() => {
        Logout();
        navigate('/')
        window.location.reload();
    })

    return (
        <></>
    );
}

export default LogoutPage;