import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {PerformRequest, GET, POST} from '../../utils/JsonFetcher';
import Papa from 'papaparse';
import { useFilePicker } from 'use-file-picker';
import {SetAlert} from "../../utils/AlertUtils";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "../../components/Modal";

function Import({currentAccount}) {
    const [importLoading, setImportLoading] = useState(false);
    const [importComplete, setImportComplete] = useState(false);
    const [accountImport, setAccountImport] = useState("");
    const [fridges, setFridges] = useState("");
    const [loading, setLoading] = useState(true);
    const [importObject, setImportObject] = useState({});
    const [itemsFound, setItemsFound] = useState(null);

    const fetchData = async () => {
        let result = await PerformRequest('fridge/accounts');

        if (result) {
            setFridges(result.accounts);

            if(currentAccount) {
                let result2 = await PerformRequest('fridge/accounts/' + currentAccount);
                if(result2) {
                    var accountObject = {
                            value: result2.id,
                            label: result2.name + " - " + result2.type
                    };

                    setAccountImport(accountObject);
                }
            }
            setLoading(false);
        }
    }

    const {openFilePicker, filesContent, isloading} = useFilePicker({
        accept: '.csv',
        onFilesSelected: ({plainFiles, filesContent, errors}) => {
            Papa.parse(plainFiles[0], {
                complete: async function (results) {
                    var descriptionColumn = null;
                    var amountColumn = null;
                    var dateColumn = null;
                    var index = 0;

                    var headers = results.data[0];

                    headers.map(header => {
                        header = header.toLowerCase();

                        if (header.includes("date")) {
                            dateColumn = index;
                        } else if (header.includes("amount")) {
                            amountColumn = index;
                        } else if (header.includes("description")) {
                            descriptionColumn = index;
                        }

                        index += 1;
                    });

                    var importObject = {
                        data: results.data,
                        descriptionColumn: descriptionColumn,
                        dateColumn: dateColumn,
                        amountColumn: amountColumn
                    }

                    setItemsFound(results.data.length);
                    setImportObject(importObject);
                }
            });
        },
    });

    const submit = async () => {
        setImportLoading(true);

        var accountId = "";

        if(accountImport) {
            if(typeof(accountImport) === "object") {
                accountId = accountImport.value;
            } else {
                accountId = accountImport;
            }
        }

        let result = await PerformRequest('fridge/accounts/' + accountId + "/import", 'POST', importObject);
        setImportLoading(false);
        window.location.reload();
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    if (loading) {
        return <div></div>;
    }

    var importComponent = <div className="card card-section shadow p-3 mb-3 rounded">
        <div className="card-body">
            <div>
                <h6>
                    Select an Account and CSV File then click "Submit" to Import Data.
                </h6>
                <div className="input-group mb-3">
                    <span className="input-group-text">Account:</span>

                    <Select
                        className="form-control select-container"
                        options={fridges.map(account => {
                            return {
                                value: account.account.id,
                                label: account.account.name + " - " + account.account.type
                            };
                        })}
                        defaultValue={accountImport}
                        onChange={e => setAccountImport(e.value)}
                    />
                </div>
                <div className="input-group mb-3">
                    <button className="btn btn-outline-primary mb-3" onClick={openFilePicker}>Select CSV</button>
                </div>

                {itemsFound != null &&
                    <div className="input-group mb-3">
                        <p>Items Found: {itemsFound}</p>
                    </div>
                }
            </div>
        </div>
    </div>;

    return (
        <Modal title="Import Data" buttonText="Import Data" body={importComponent}
               buttonClasses="btn btn-outline-primary mb-3" saveButtonText="Submit"
               iconClass="bi bi-cloud-arrow-up-fill" SaveHandler={submit}/>
    );
}

export default Import;