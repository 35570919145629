import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import logo from './logo.png';
import {PerformRequest, GET, POST} from './utils/JsonFetcher';
import {IsLoggedIn} from './utils/TokenStorage';

function Header() {
    const [profile, setProfile] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('users/profile');

            if(result) {
                setProfile(result);
                setLoading(false);
            }
        }

        if(IsLoggedIn()) {
            fetchData()
                .catch(console.error);
        }
    }, []);

    return (
        <>
            <div className="side-nav navbar navbar-light navbar-vertical navbar-expand-xl">
                <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                    <div className="navbar-vertical-content scrollbar">
                        <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                            <li className="nav-item">
                                <div className="d-flex align-items-center">
                                    <ul className="nav collapse show" id="dashboard">
                                        <li className="nav-item">
                                            <Link className={"nav-link"} to={"/premium/signup"}>
                                                <span className="header-menu-text"><i className="bi bi-bag-plus-fill"></i> Go Pro</span>
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className={"nav-link"} to={"/"}>
                                                <span className="header-menu-text"><i className="bi bi-bank2"></i> My Accounts</span>
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className={"nav-link"} to={"/households/" + profile.householdId}>
                                                <span className="header-menu-text"><i className="bi bi-people-fill"></i> Users</span>
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link className={"nav-link"} to={"/logout"}>
                                                <span className="header-menu-text"><i className="bi bi-door-closed-fill"></i> Sign Out</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;