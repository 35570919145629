import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {PerformRequest} from "../../utils/JsonFetcher";
import ListItem from "./ListItem";
import Modal from "../../components/Modal";
import AddListItemModal from "./modals/AddListItemModal";

function List() {
    let { id } = useParams();

    const [listItems, setListItems] = useState([]);
    const [sort, setSort] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('grocery_lists/my');

            if(result) {
                setListItems(result);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    const handleSort = () => {
        setSort(!sort)
    }

    if(loading) {
        return <div></div>;
    }

    var listItemSorted = "";

    if(sort) {
        if(listItems.length) {
            listItemSorted = listItems.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }

        var sortRender =  <i className="bi bi-sort-alpha-down"></i>;
    } else {
        if(listItems.length) {
            listItemSorted = listItems.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
        }

        var sortRender = <i className="bi bi-sort-alpha-down-alt"></i>;
    }

    var addItemModalButton = <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="bi bi-stack"></i> Add Item
                            </button>;
    return (
        <div key={"list_" + id}>
            <h3 className="mb-4">List</h3>

            <div className="d-flex justify-content-between mb-4">
                <div>
                    <form className="w-100 me-3" role="search">
                        <input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
                    </form>
                </div>
            </div>

            <div className="d-flex justify-content-between mb-4">
                <div className="mb-4">
                    <AddListItemModal button={addItemModalButton} />
                </div>

                <div className="mb-4">
                    <Link to={"/fridges/create"} className="unstyled-link">
                        <button type="button" className="btn btn-outline-primary">
                            <i className="bi bi-upc-scan"></i> Scan Item
                        </button>
                    </Link>
                </div>
            </div>

            <div className="row text-start mb-5">
                <ul className="list-group border-0">
                    { listItemSorted &&
                     <ListItem items={listItemSorted} />
                    }
                </ul>
            </div>

        </div>
    );
}

export default List;