import { GetToken, Logout } from './TokenStorage';
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';

export async function PerformRequest(apiUrl, type = "GET", body = null, noLogin = false) {
    try {
        const BASE_URL = process.env.REACT_APP_API_URL + "/api/";
        var url = BASE_URL + apiUrl;
        var requestType = (type) ? type : 'GET';

        if(!noLogin) {
            let token = await GetToken();
            var options = {
                method: requestType,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            };
        } else {
            var options = {
                method: requestType,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
        }

        if(body) {
            options.body = JSON.stringify(body);
        }

        let response = await fetch(url, options);
        let responseJson = await response.json();

        if(response.status == 200) {
            // Logout();
        }

        return responseJson;
    } catch (error) {
        console.error(error);
    }
}