import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import CategoryHelper from "./CategoryHelper";
import {PerformRequest} from "../../utils/JsonFetcher";
import ClipLoader from "react-spinners/ClipLoader";

function FridgeItem({selectedItems, items, CheckMethod, uncategorizedOnly}) {
    const [selected, setSelected] = useState([]);

    if(uncategorizedOnly) {
        items = items.filter(item => item.categoryId == null);
    }

    var mapped = items.map(({ id, name, date, categoryId, categoryName, total }) =>
        <tr>
            <td>{name}</td>
            <td>{date}</td>
            <td>
                <CategoryHelper id={id} defaultSelectedValue={categoryName} />
            </td>
            <td className={total > 0 ? "text-success" : "text-danger"}>{<CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</td>
        </tr>
    );

    return (
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Category</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                {mapped}
            </tbody>
        </table>
);
}

export default FridgeItem;