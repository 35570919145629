import React, {useState, useEffect} from "react";
import {PerformRequest} from "../../utils/JsonFetcher";
import {useParams} from "react-router";

function Recipe() {
    let { id } = useParams();

    const [recipe, setRecipe] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('recipe/fetch/' + id);

            if(result) {
                setRecipe(result);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div></div>;
    }

    var recipeIngredients = "";

    if(recipe && recipe.ingredients) {
        var recipeIngredients = recipe.ingredients.map((ingredient) => {
            return <li>{ingredient}</li>
        });
    }

    var imagePath = "../../recipe_images/" + recipe.image;

    return (
        <>
            <h3 className="mb-4">{recipe.name}</h3>
            <p>
                <a target="_blank" href={recipe.url}>View Full Recipe</a>
            </p>

            <img src={imagePath} style={{width: 18 + "rem"}} />

            <h5 className="mt-2">Ingredients: </h5>
            {recipeIngredients}
        </>
    );
}

export default Recipe;