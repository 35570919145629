import React from "react";
import { Line } from "react-chartjs-2";
import { ChartData, ChartArea } from 'chart.js';

function LineChart({data, label}) {
    var labels = [];
    var chartData = [];

    if(data.length > 0) {
        data.forEach((item) => {
            labels.push(item.d_month);
            chartData.push(Math.abs(item.total));
        })
    }

    const dataSpending = {
        labels: labels,
        datasets: [
            {
                label: label,
                data: chartData,
                fill: true,
                backgroundColor: "rgb(138,0,0)",
                borderColor: "rgb(255,0,0)"
            }
        ]
    };

    return (
        <>
            <Line data={dataSpending} />
        </>
    );
}

export default LineChart;