export function HasAlert() {
    return GetAlert();
}

export function GetAlert() {
    return localStorage.getItem('alert');
}

export function SetAlert(message) {
    localStorage.setItem('alert', message);
}

export function ClearAlert() {
    localStorage.removeItem('alert');
}