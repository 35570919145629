import React, {useEffect, useState} from 'react';
import {PerformRequest, GET, POST, PUT} from '../utils/JsonFetcher';
import {Link, useNavigate, useSearchParams} from "react-router-dom";

function Verify() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    var email = searchParams.get("email");
    var token = searchParams.get("token");

    useEffect(() => {
        const fetchData = async () => {
            var body = {
                email: email,
                token: token
            }

            let result = await PerformRequest('users/verify-email', POST, body, true);

            if(result) {
                navigate('/login')
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    return (
        <div>
        </div>

    )
        ;
}

export default Verify;