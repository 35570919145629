import React, {useEffect, useState} from 'react';
import {PerformRequest} from "../../utils/JsonFetcher";
import {Link, redirect, useNavigate} from "react-router-dom";
import AccountHelper from "./AccountHelper";
import Modal from "../../components/Modal";

function FridgeCreate() {
    const [name, setName] = useState("");
    const [startingBalance, setStartingBalance] = useState("");
    const [accountType, setAccountType] = useState("");
    const [personalOrBusiness, setPersonalOrBusiness] = useState("Personal");
    const [apr, setApr] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [limits, setLimits] = useState("");
    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();

        const setData = async (name) => {
            let payload = {
                name: name,
                startingBalance: startingBalance,
                accountType: accountType,
                personalOrBusiness: personalOrBusiness,
                apr: apr
            };
            let result = await PerformRequest('fridge/accounts', "PUT", payload);

            if (result && result['error'] != undefined) {
                setLoginError(true);
            } else {
                window.location.reload();
            }
        }

        setData(name)
            .catch(console.error);
    };

    const fetchData = async () => {
        let result = await PerformRequest('users/limits');

        if (result) {
            setLimits(result);

            if (result.currentAccounts + 1 > result.accountLimit) {
                setLoginError(true);
            }
        }
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    var content = <div className="card card-section shadow p-3 mb-3 rounded">
        <div className="card-header card-header-tile mb-4">Add Account</div>

        <div className="card-body">
            {loginError &&
                <h5 className="text-danger"><i className="bi bi-exclamation-triangle-fill"></i> Please <Link
                    to="/premium/signup">upgrade</Link> to add more Accounts!</h5>
            }

            {!loginError &&
                <>
                    <form onSubmit="#">
                        <div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Name:</span>
                                <input
                                    id="name"
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    placeholder="Chase"
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Type:</span>
                                <AccountHelper handler={setAccountType}/>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Current Balance:</span>
                                <input
                                    id="starting_balance"
                                    className="form-control"
                                    type="text"
                                    value={startingBalance}
                                    placeholder="5000"
                                    onChange={e => setStartingBalance(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                </>
            }
        </div>
    </div>;

    return (
        <>
            <Modal title="Import Data" buttonText="Add Account" body={content}
                   buttonClasses="btn btn-outline-primary mb-3" saveButtonText="Submit"
                   iconClass="bi-stack" SaveHandler={handleSubmit}/>
        </>
    );
}

export default FridgeCreate;