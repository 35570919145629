import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {PerformRequest} from "../../utils/JsonFetcher";
import {Img} from 'react-image';

function Favorites() {
    const [recipes, setRecipes] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('recipe/favorites');

            if(result) {
                setRecipes(result);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div></div>;
    }

    var recipeItems = recipes.map((recipe) => {
        var imagePath = "../../recipe_images/" + recipe.image;
        var backgroundImage = "https://picsum.photos/id/312/3888/2592";

        return <div className="row mb-3">
            <Link to={"/recipe/" + recipe.id}>
                <div className="card" style={{width: 18 + "rem"}}>

                    <Img className="card-img-top" src={[imagePath, backgroundImage]} alt={recipe.name} />
                    <div className="card-body">
                        <h5 className="card-title">{recipe.name}</h5>
                    </div>
                </div>
            </Link>
        </div>
    });

    return (
        <>
            <h3 className="mb-4">Favorite Recipes</h3>

            {recipeItems}
        </>
    );
}

export default Favorites;