import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {PerformRequest} from "../../utils/JsonFetcher";
import {Img} from 'react-image';

function Recipe() {
    const [recipes, setRecipes] = useState("");
    const [favorites, setFavorites] = useState("");

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('recipe/match');
            let favorites = await PerformRequest('recipe/favorites');

            if(result) {
                setRecipes(result);

                if(favorites) {
                    let favoritedIds = favorites.map((favorite) => { return favorite.id });
                    setFavorites(favoritedIds);
                }
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div></div>;
    }

    const FavoriteHandler = async (recipeId) => {
        let favorites = await PerformRequest('recipe/favorite/' + recipeId);
        if(favorites) {
            let favoritedIds = favorites.map((favorite) => { return favorite.id });
            setFavorites(favoritedIds);
        }
    };

    var recipeItems = recipes.map((recipe) => {
        var imagePath = "../../recipe_images/" + recipe.image;
        var backgroundImage = "https://picsum.photos/200";
        let favorited = favorites.includes(recipe.id) ? <i className="bi bi-suit-heart-fill text-danger"></i>: <i className="bi bi-suit-heart text-danger"></i>;

        return <div className="row mt-3 mb-3">
                    <Link className="unstyled-link" to={"/recipe/" + recipe.id}>
                        <div className="card" style={{width: 18 + "rem"}}>
                            <div className="favorite-icon"
                                 onClick={(e) => {e.preventDefault(); FavoriteHandler(recipe.id)}}
                            >
                                {favorited}
                            </div>
                            <Img className="card-img-top" src={[imagePath, backgroundImage]} alt={recipe.name} />
                            <div className="card-body">
                                <h5 className="card-title">{recipe.name}</h5>
                            </div>
                        </div>
                    </Link>
                </div>
    });

    return (
        <>
            <h3 className="mb-4">Recipes From Your Fridge</h3>

            <Link to={"/favorites"} className="unstyled-link">
                <button type="button" className="btn btn-outline-primary">
                    <i className="bi bi-stack"></i> View Favorites
                </button>
            </Link>

            {recipeItems}
        </>
    );
}

export default Recipe;