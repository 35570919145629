import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {PerformRequest, GET, POST} from '../../utils/JsonFetcher';
import Papa from 'papaparse';
import { useFilePicker } from 'use-file-picker';
import {SetAlert} from "../../utils/AlertUtils";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "../../components/Modal";
import AccountHelper from "./AccountHelper";

function EditAccount({currentAccount}) {
    const [importLoading, setImportLoading] = useState(false);
    const [importComplete, setImportComplete] = useState(false);
    const [name, setName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [loading, setLoading] = useState(true);
    const [accountObject, setAccountObject] = useState({});
    const [itemsFound, setItemsFound] = useState(null);

    const fetchData = async () => {
        if(currentAccount) {
                let result2 = await PerformRequest('fridge/accounts/' + currentAccount);
                if(result2) {
                    var accountObj = {
                        value: result2.id,
                        label: result2.type
                    };

                    setAccountObject(accountObj);

                    setName(result2.name);
                    setAccountType(result2.type);
                }
            }
            setLoading(false);
    }

    const submit = async () => {
        setImportLoading(true);

        let result = await PerformRequest('fridge/accounts/' + currentAccount, 'PUT', {name:name, accountType:accountType});
        setImportLoading(false);
        window.location.reload();
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    if (loading) {
        return <div></div>;
    }

    var importComponent = <div className="card card-section shadow p-3 mb-3 rounded">
        <div className="card-body">
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Name:</span>
                    <input type="text" defaultValue={name} onChange={e => setName(e.target.value)} />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text">Type:</span>

                    <AccountHelper handler={setAccountType} defaultAccount={accountObject} />
                </div>
            </div>
        </div>
    </div>;

    return (
        <Modal title="Edit Account Info" buttonText="Edit Account Info" body={importComponent}
               buttonClasses="btn btn-outline-primary mb-3" saveButtonText="Submit"
               iconClass="bi bi-pencil-fill" SaveHandler={submit}/>
    );
}

export default EditAccount;