import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {PerformRequest} from "../../utils/JsonFetcher";
import {Link} from "react-router-dom";
import Invitation from "./Invitation";

function Invitations() {
    let { id } = useParams();

    const [invitations, setInvitations] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('invitations');
            if(result) {
                setInvitations(result);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    if (loading) {
        return <div>loading</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <Link to={"/households/" + id + "/invitations"}>Invite User</Link>

            <ul className="list-group">
                <Invitation items={invitations} />
            </ul>
        </div>
    );
}

export default Invitations;