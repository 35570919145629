import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {PerformRequest} from "../../utils/JsonFetcher";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FoodType from "./components/FoodType";

function FridgeItemEdit() {
    const navigate = useNavigate();
    let { id, itemId } = useParams();

    const [name, setName] = useState("");
    const [quantity, setQuantity] = useState("");
    const [type, setType] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [category, setCategory] = useState({});
    const [fridges, setFridges] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('fridge/item/' + itemId);
            if(result) {
                setName(result.name);
                setQuantity(result.quantity);
                setCategoryId(result.categoryId);

                if(result.expiration) {
                    setExpirationDate(new Date(result.expiration));
                }
            }

            let result2 = await PerformRequest('fridge/categories');
            if(result2) {
                setFridges(result2);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        const setData = async (name, quantity, expirationDate, categoryId, type) => {
            let payload = {name: name, quantity: quantity, expirationDate: expirationDate, categoryId: categoryId, type:type};
            let result = await PerformRequest('fridge/item/' + itemId, "POST", payload);
            if(result) {
                navigate('/fridge/' + id)
            }
        }

        setData(name, quantity, expirationDate, categoryId)
            .catch(console.error);
    };

    const handleDelete = e => {
        e.preventDefault();

        const deleteData = async (itemId) => {
            let result = await PerformRequest('fridge/item/' + itemId, "DELETE");
            if(result) {
                navigate('/fridge/' + id)
            }
        }

        deleteData(itemId)
            .catch(console.error);
    };

    if (loading) {
        return <div>loading</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <img src="https://fakeimg.pl/200x200/" className="rounded mx-auto d-block" alt="food image" />

            <form onSubmit={handleSubmit}>
                <div className="row mb-3 col-12">
                    <label htmlFor="name" className="form-label">
                        Name:
                    </label>

                    <input
                        id="name"
                        className="form-control"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>

                <div className="row mb-3 col-12">
                    <label htmlFor="quantity" className="form-label">
                        Quantity:
                    </label>

                    <div className="col-6">
                        <input
                            id="quantity"
                            className="form-control"
                            type="text"
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                        />
                    </div>

                    <div className="col-6">
                        <FoodType defaultSelectedValue={type} FoodSelectHandler={setType} />
                    </div>
                </div>

                <div className="mb-3 col-12">
                    <label htmlFor="expirationDate" className="form-label">
                        Expiration Date:
                        <DatePicker className="form-control" selected={expirationDate} onChange={e => setExpirationDate(e)} />
                    </label>
                </div>

                <div className="row mb-3 col-12">
                    <label htmlFor="category" className="form-label">
                        Location

                        <Select
                            defaultValue={fridges.map(fridge => {
                                if(fridge.id == categoryId) {
                                    return { value: fridge.id, label: fridge.name }
                                }
                            })}
                            options={fridges.map(fridge => {
                                return { value: fridge.id, label: fridge.name };
                            })}
                            onChange={e => setCategoryId(e.value)}
                        />
                    </label>
                </div>

                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-lg btn-primary"><i className="bi bi-check2"></i>
                        Submit</button>
                </div>
            </form>

            <br />
            <form onSubmit={handleDelete}>
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-lg btn-danger"><i className="bi bi-trash3"></i>
                         Delete</button>
                </div>
            </form>
        </>
    );
}

export default FridgeItemEdit;