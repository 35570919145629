import React from "react";
import {Link} from "react-router-dom";

function HouseholdMember({items}) {
    if(!items || items.length == 0){
        return <div></div>;
    }

    return items.map(({ id, fullName }) => (

        <li key={"household-item-" + id} className="list-group-item shadow-sm rounded-0 fridge-list my-1 ms-2">
            <div className="d-flex justify-content-between">
                <div className="fw-bold">{fullName}</div>

                <i className="bi bi-trash text-danger"></i>
            </div>
        </li>
    ));
}

export default HouseholdMember;