import React from "react";
import CreatableSelect from 'react-select/creatable';

function AccountHelper({handler, defaultAccount}) {
    var types = [
        {value: "401K", label: "401K"},
        {value: "403B", label: "403B"},
        {value: "Car Loan", label: "Car Loan"},
        {value: "Checking Account", label: "Checking Account"},
        {value: "Credit Card", label: "Credit Card"},
        {value: "Debit", label: "Debit"},
        {value: "Investment", label: "Investment"},
        {value: "Loan", label: "Loan"},
        {value: "Other Credit", label: "Other Credit"},
        {value: "Real Estate", label: "Real Estate"},
        {value: "Mortgage", label: "Mortgage"},
        {value: "Savings Account", label: "Savings Account"},
        {value: "Stock", label: "Stock"},
        {value: "Student Loan", label: "Student Loan"}
    ];

    const check = (e) => {
        handler(e.value);
    }

    return (
        <>
            <CreatableSelect
                className="basic-single form-control"
                classNamePrefix="select"
                isClearable
                isSearchable
                name="color"
                options={types}
                onChange={check}
                defaultValue={defaultAccount}
            />
        </>
    );
}

export default AccountHelper;