export function GetToken() {
    return (localStorage.getItem('token')) ? localStorage.getItem('token') : false;
}

export function SetToken(token) {
    localStorage.setItem('token', token);
}

export function IsLoggedIn() {
    return (GetToken()) ? true : false;
}

export function Logout() {
    localStorage.clear();
}

export function GetUser() {
    return JSON.parse(localStorage.getItem('user'));
}

export function SetUser(data) {
    localStorage.setItem('user', JSON.stringify(data));
}