import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import MainPage from "./fridge/images/main_page.png";

function HomePage() {
    const navigate = useNavigate();

    return (
        <div>
            <div className="landing landing-full">
                <div className="navbar bg-dark homepage-z-front">
                    <div className="container homepage-container">
                        <div>
                          <h4 className="pt-2">MoneyMan Tracker</h4>
                        </div>
                        <div>
                            <Link to="/login">
                                Login
                            </Link>

                            <Link to="/premium/signup">
                                Register
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row landing-content homepage-container star-container">
                        <section>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </section>

                        <div className="col-sm-12 col-md-4 mb-3 homepage-z-front">
                            <h4 className="mb-3">Take control of your finances</h4>
                            <h5 className="mb-3">Understand your net worth and quickly see where your accounts are at a
                                glance.</h5>

                            <button
                                className="btn btn-lg btn-outline-primary text-white border-white button-white-hover">
                                <Link to="/premium/signup">Start Now</Link>
                            </button>
                        </div>

                        <div className="landing-img col-md-8 col-sm-12 homepage-z-front">
                            <Link to="/premium/signup">
                                <img className="img-fluid" src={MainPage}/>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default HomePage;