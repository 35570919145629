import React from "react";

function Modal({title, body, buttonText, buttonClasses, iconClass, SaveHandler, saveButtonText }) {
    if(!title || !body) {
        return <></>;
    }

    if(!buttonClasses) {
        buttonClasses = "btn btn-danger";
    }

    if(!saveButtonText) {
        saveButtonText = "Save";
    }

    function makeId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    var myId = makeId(10);

    return (
        <div>
            <button className={buttonClasses} data-bs-toggle="modal" data-bs-target={"#" + myId}>
                {iconClass &&
                    <i className={iconClass}>&nbsp;</i>
                }
                {buttonText}
            </button>

            <div className="modal fade" id={myId} tabIndex="-1" aria-labelledby={myId}
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-success">{title}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {body}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={SaveHandler}>{saveButtonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;