import React from 'react';
import {GetFoodSelectOptions} from "../../utils/FoodUtils";
import {PerformRequest} from "../../utils/JsonFetcher";
import {useParams} from "react-router";
import { useNavigate } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';

function FridgeItemAdd() {
    const navigate = useNavigate();
    let { id } = useParams();
    const foodItems = GetFoodSelectOptions();
    const createFoodItem = async (name) => {
        if(name && name.value) {
            let result = await PerformRequest('fridge/item/category/' + id, 'PUT', {name: name.value});
            if (result) {
                navigate("/fridge/" + id + "/item/" + result.id);
            }
        }
    };

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-evenly mt-5 gap-5">
                <h5>Add Item</h5>
                <h6>Select Food</h6>

                <div className="row col-12">
                    <CreatableSelect
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={foodItems[0]}
                        isClearable
                        isSearchable
                        name="color"
                        options={foodItems}
                        onChange={createFoodItem}
                    />
                </div>

                <p>
                 or
                </p>

                Scan

                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                     className="bi bi-upc-scan" viewBox="0 0 16 16">
                    <path
                        d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
                </svg>
            </div>
        </>
    );
}

export default FridgeItemAdd;