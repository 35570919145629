import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {PerformRequest, GET, POST} from '../../utils/JsonFetcher';
import CurrencyFormat from 'react-currency-format';
import Chart from "./Chart";
import LineChart from "./LineChart";
import ClipLoader from "react-spinners/ClipLoader";
import Car from './images/car.jpeg';
import Diploma from './images/diploma.jpeg';
import House from './images/house.jpeg';
import Money from './images/money.jpeg';
import Stock from './images/stock.jpeg';
import Retirement from './images/retirement.jpg';
import Import from "./Import";
import Modal from "../../components/Modal";
import FridgeCreate from "./FridgeCreate";

function Fridges() {
    const [fridges, setFridges] = useState("");
    const [loading, setLoading] = useState(true);
    const [limits, setLimits] = useState("");
    const [netWorth, setNetWorth] = useState("");

    const fetchData = async () => {
        let result = await PerformRequest('fridge/accounts');
        let result2 = await PerformRequest('users/limits');

        if(result) {
            setFridges(result.accounts);
            setNetWorth(result.net_worth);
            setLimits(result2);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div></div>;
    }

    var year = new Date();
    var year = year.getFullYear();

    var cards = fridges.map(({account, totals}) => {
        var label = (account.type == "Credit") ? "Account Spending - " + year : "Totals - " + year;
        var icon = "";
        var cardImage = "";
        var needsGraph = true;
        var priceColor = "";

        switch (account.type) {
            case "Mortgage":
                icon = <i className="bi bi-house-door-fill"></i>;
                needsGraph = false;
                cardImage = House;
                break;
            case "Car Loan":
            case "Auto Loan":
                icon = <i className="bi bi-car-front-fill"></i>;
                needsGraph = false;
                cardImage = Car;
                break;
            case "Savings":
            case "Debit":
                icon = <i className="bi bi-cash-coin"></i>;
                priceColor = "price-success";
                break;
            case "Student Loan":
                icon = <i className="bi bi-backpack-fill"></i>;
                needsGraph = false;
                cardImage = Diploma;
                break;
            case "Credit Card":
            case "Credit":
                icon = <i className="bi bi-credit-card"></i>;
                priceColor = "price-warning";
                break;
            case "Stock":
            case "Stocks":
                cardImage = Stock;
                needsGraph = false;
                icon = <i className="bi bi-graph-up-arrow"></i>;
                break;
            case "401K":
            case "401k":
            case "403b":
            case "403B":
                needsGraph = false;
                icon = <i className="bi bi-bar-chart"></i>;
                cardImage = Retirement;
            break;
        }

        return (
            <div className="col-sm-12">
                <div className="card dashboard-card card-section shadow rounded">
                    <Link className="unstyled-link" to={"/fridge/" + account.id}>
                        {cardImage &&
                            <div>
                                <img src={cardImage} className="card-img-top image-card-header" alt=""/>
                            </div>
                        }

                        {needsGraph &&
                            <div className="card-body">
                               <LineChart data={totals} label={label}/>
                            </div>
                        }

                        <div className="card-footer mt-auto d-flex justify-content-between">
                            <div>
                                <p className="account-name-text">{account.name}</p>
                                <span className="sub-text">{account.type} {icon}</span>
                            </div>

                            <div className={"pricing-text " + priceColor}><CurrencyFormat value={account.currentBalance} displayType={'text'}
                                                 thousandSeparator={true} prefix={'$'}/></div>

                        </div>
                    </Link>
                </div>
            </div>
        )
    });

    return (
        <div>
            <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                <div className="col col-sm-12">
                    <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                        <div className="card-header card-header-tile">Accounts <span
                            className="limit-text">({fridges.length} / {limits.accountLimit})</span></div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2 col-sm-12">
                                    <FridgeCreate/>
                                </div>
                                {fridges.length > 0 &&
                                    <div className="col-md-2 col-sm-12">
                                        <Import/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {fridges.length == 0 &&
                <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                    <div className="col col-sm-12">
                        <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                            <div className="card-header card-header-tile">No Accounts</div>
                            <div className="card-body">
                                <p>
                                    No Accounts Found! Please click here to add one.
                                </p>

                                <FridgeCreate/>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {netWorth != null &&
                <div className="row row-cols-sm-12 row-cols-md-4 g-4">
                    <div className="col col-sm-12">
                        <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                            <div className="card-header card-header-tile">Net Worth</div>
                            <div className="card-body">
                                <div>
                                    <CurrencyFormat className={netWorth > 0 ? "text-success" : "text-danger"}
                                                    value={netWorth} displayType={'text'} thousandSeparator={true}
                                                    prefix={'$'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row row-cols-sm-12 row-cols-md-4 g-4">
                {cards}
            </div>
        </div>
    );
}

export default Fridges;